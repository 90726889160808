/* 评分表单项组件 */
<template>
  <el-form-item :label="itemData.label" v-bind="[itemData]" :prop="itemKey">
    <el-rate class="rate-input" v-model="itemVal" label="" v-bind="[itemData]"  @change="change"></el-rate>
  </el-form-item>
</template>

<script>

export default {
  props: {

    // 表单项配置
    itemData: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 表单键值
    itemKey: String,

    // 父组件双向绑定的model值
    value: [Number, Array],

    // 当前所在表单组配置项,用于事件函数的参数
    groupData: Object,

    // 表单配置项,用于事件函数的参数
    formData: Array,

    // 表单数据,用于事件函数的参数
    form: Object

  },
  model: {
    // 与父组件进行双向绑定
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      itemVal: this.value
    }
  },
  watch: {
    value() {
      this.itemVal = this.value
    }
  },
  methods: {
    change(val) {
      if (typeof (this.itemData.changeEvent) === 'function') {
        this.itemData.changeEvent(val, this.form, this.groupData, this.formData)
      }
      this.$emit('change', this.itemVal)
    }
  }
}

</script>
